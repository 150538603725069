import React from "react"
import { Box, ThemeProvider } from "@chakra-ui/core"
import customTheme from "../theme/theme"
import { Footer } from "../footer/Footer"
import { Global } from "@emotion/core"
import CookieConsent from "react-cookie-consent"
import { ScrollUp } from "../navigation/scroll/ScrollUp"

type Props = {}

export const Layout: React.FC<Props> = ({ children }) => {
  return (
    <ThemeProvider theme={customTheme}>
      <Global
        styles={theme => ({
          body: {
            fontFamily: "Montserrat",
            margin: "0px",
            lineHeight: "2rem",
            background: "#F3F3F3",
            overflowWrap: "break-word",
            wordWrap: "break-word",
            msWordBreak: "break-word",
            wordBreak: "break-word",
            msHyphens: "auto",
            MozHyphens: "auto",
            WebkitHyphens: "auto",
            hyphens: "auto",
          },
          ".markdownText p": {
            margin: "0px",
            marginTop: "2rem",
            fontSize: "1.2rem",
          },
          h1: {
            color: "#004552",
            margin: "0px",
          },
          h2: {
            color: "#004552",
            marginTop: "5rem",
          },
          h3: {
            color: "#004552",
            marginTop: "5rem",
            padding: "0px",
          },
          h4: {
            color: "black",
            marginTop: "2rem",
            padding: "0px",
          },
          h5: {
            color: "#004552",
            marginTop: "2rem",
            padding: "0px",
          },
          a: {
            textDecoration: "none",
            outline: "none",
            color: "inherit",
          },
        })}
      />
      <Box>
        <Box m="0rem" minH="86vh">
          {children}
        </Box>
        <Footer />
        <ScrollUp />
        <CookieConsent
          location="bottom"
          buttonText="Ok"
          cookieName="cookiesdassd1234566"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          Tällä sivustolla käytetään evästeitä. Jatkamalla sivuston käyttöä
          hyväksyt evästeiden käytön.
        </CookieConsent>
      </Box>
    </ThemeProvider>
  )
}
